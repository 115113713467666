import React from "react"
import { graphql } from "gatsby"
import headerConfig from "../../.forestry/content/settings/header.json"
import footerConfig from "../../.forestry/content/settings/footer.json"
import Layout from "../components/layout/layout"

export default function Template({ data: { pagesJson: page } }) {
 
  return (
    <div>
      <Layout page={page} headerConfig={headerConfig} footerConfig={footerConfig}/>
    </div>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    pagesJson(title: {eq: $title }) {
      blocks {
        address
        alignment
        buttonText
        buttonLink
        buttons {
          link
          text
        }
        contactHeading
        contactSubHeading
        directionsUrl
        email
        fax
        formHeading
        forms {
          icon
          title
          pdf
          onlineLink
        }
        headingLines {
          headingLine
        }
        heading
        icon
        image
        images {
          image
        }
        insurance {
          logo
        }
        itemOne {
          text
          icon
        }
        itemTwo {
          textOne
          textTwo
          textColor
          link
          icon
        }
        itemThree {
          text
          icon
        }
        latitude
        linkText
        list
        longitude
        marker
        numbers {
          text
          number
        }
        paragraph
        paragraphTwo
        services {
          icon
          title
          description
        }
        staff {
          doctorOrStaff
          imageOne
          imageTwo
          name
          paragraphOne
          paragraphTwo
        }
        subHeading
        template
        text
        title
        toggleOptionOne
        toggleOptionTwo
        video
      }
      title
      slug
    }
  }
`